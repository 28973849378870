import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// core components
import Grid from "@material-ui/core/Grid"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/landing-webdesign.js"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const getImages = graphql`
  {
    file(relativePath: { eq: "contractor-website-design.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

const useStyles = makeStyles(styles)
export default function SectionCards() {
  const classes = useStyles()
  const data = useStaticQuery(getImages)
  return (
    <div style={{ padding: "60px 0px 60px 0px" }}>
      <div className={classNames(classes.section, classes.sectionDark)}>
        <Grid container justify="center">
          <Grid item md={5} sm={5} lg={5} xs={10}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              alt="contractor-web-design"
            />
          </Grid>
          <Grid item lg={4} md={10}>
            <h3 className={classes.title}>Launch with Confidence</h3>
            <h6 className={classes.description}>Contractor Web Design</h6>
            <p className={classes.description}>
              With a great SEO strategy, your website will quickly rank higher
              in search results. But SEO and web design are complementary.
              Without a good design strategy, your website won’t convert even if
              it reaches the first spot in Google’s results. Statistics show how
              online consumers are put off by outdated web design. Most people
              are looking for modern, user-friendly, and mobile-optimized
              websites.
            </p>
            <p className={classes.description}>
              The team at Atlas Agency provides exactly those types of websites.
              Our team is proficient in modern web design solutions. We only
              work with the best hosting platforms and make sure that your
              website gets the makeover it deserves. Our web designers follow
              your vision and help you launch your new website with confidence.
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
