import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import Grid from "@material-ui/core/Grid"

import styles from "../../jss/intro.js"

const useStyles = makeStyles({
  ...styles,
  paragraph: {
    color: "#212121",
  },
  section2: {
    padding: "30px 0",
  },
  sectionDiv: {
    padding: "0px 0px 60px 0px",
  },
})

export default function ProductSection() {
  const classes = useStyles()

  return (
    <div>
      <Grid container justify="center" className={classes.sectionDiv}>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <h2 className={classes.title} style={{ color: "#273746" }}>
            WHY CHOOSE US?
          </h2>
          <p className={classes.description}>
            Atlas Agency is a marketing company based in Toronto. Our team
            consists of SEO experts and qualified web designers. We have the
            needed experience in contractor website design. With our expertise,
            we can easily help you optimize and improve your business’s online
            presence. We use innovative platforms and technologies to host and
            maintain your website.
          </p>
        </Grid>
      </Grid>
    </div>
  )
}
