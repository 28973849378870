import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

import Grid from "@material-ui/core/Grid"
import InfoArea from "../../components/InfoArea/InfoArea.js"

import styles from "../../jss/intro.js"
import SpellcheckIcon from "@material-ui/icons/Spellcheck"

import CodeIcon from "@material-ui/icons/Code"

import LinkIcon from "@material-ui/icons/Link"

import CreateIcon from "@material-ui/icons/Create"
const useStyles = makeStyles(styles)

export default function ProductSection() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <Grid container justify="center">
        <Grid item xs={10} sm={12} md={8} lg={8} align="center">
          <h2 className={classes.title}>Contractor Website Design </h2>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={11}
          align="center"
          style={{ padding: "20px 0px 0px 0px" }}
        >
          <p className={classes.description}>
            {" "}
            Marketing strategies have evolved greatly over the past years.
            Industries are shifting to digital means and construction is no
            exception. Whether you work solo or in a company, now is the time to
            act and improve your online presence. With the help of modern web
            design, your contractor website can quickly get the makeover it
            needs.
          </p>
          <p className={classes.description}>
            {" "}
            Atlas Agency is an experienced SEO & web design company from
            Toronto. We have helped many companies with their contractor website
            design. Our team also does thorough market and keyword analysis,
            implements proven SEO strategies, and can make sure that your
            website reaches Google’s top results.{" "}
          </p>
        </Grid>
      </Grid>
      <div style={{ padding: "20px 0px 0px 0px" }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Custom Made Websites"
              description="We provide resources that will make your website stand out from competitors. We don't use website templates and create everything from scratch. Our custom website approach provides contractors with professional websites."
              icon={CreateIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Hosting"
              description="We will not only create your website but we will ensure that it is available to customers 24/7. Along with our user-friendly design, customer service will become much easier for your contractor website. We can also help in securing your desired domain and connecting it to your site."
              icon={SpellcheckIcon}
              iconColor="info"
              vertical
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <InfoArea
              title="Mobile Optimization"
              description="More and more online consumers are searching for a local service on their mobile devices. To make your website appealing to mobile visitors, Atlas Agency will optimize your website for mobile, tables, and desktop screens. "
              icon={CodeIcon}
              iconColor="info"
              vertical
            />
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
